'use client';

import { memo } from 'react';
import Link from 'next/link';
import { useHumbergerMenu } from '@/components/providers/HumbergerMenuProvider/useHumbergerMenu';
import { globalNavLinks, pageRoute } from '@/configs/navigation';
import { useScrollBasedState } from '@/hooks/useScrollBasedState/useScrollBasedState';
import { concatClassNames } from '@/utils/concatClassNames';
import { CompanyLogoText } from '../../Graphics/CompanyLogoText/CompanyLogoText';
import { DynamicHeadingElement } from '../../Utils/DynamicHeadingElement/DynamicHeadingElement';
import * as s from './style.css';

type Props = {
    /** ロゴに指定するタグ名 */
    logoTagType?: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    /**
     * 最初から表示するかどうか
     *
     * @default true
     */
    defaultVisible?: boolean;
    /** リンククリック時のコールバック */
    onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

/** サイト上部の共通ナビゲーション */
export const GlobalStickyNav: React.FC<
    Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = memo(({ onLinkClick, logoTagType = 'h1', defaultVisible = true, className, ...props }) => {
    const { handleClose } = useHumbergerMenu();
    const { scrollDirection, scrollState } = useScrollBasedState({
        stopDelay: 3000,
        defaultDirection: 'stopped',
        defaultState: defaultVisible ? 'stopped' : 'scrolling',
    });
    // 上方向にスクロールしているか、スクロールが停止したとみなされたらナビゲーションの表示を切り替える
    const isNavVisible = scrollDirection === 'up' || scrollState === 'stopped';
    const rootClassName = concatClassNames(
        s.rootContainer[isNavVisible ? 'show' : 'hide'],
        className,
    );

    const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
        handleClose();
        onLinkClick?.(event);
    };

    return (
        <div className={rootClassName} {...props}>
            <nav className={s.navRoot}>
                <div>
                    <Link
                        href={pageRoute.home}
                        aria-label="ホームへのリンク"
                        onClick={handleLinkClick}
                    >
                        <DynamicHeadingElement as={logoTagType}>
                            <CompanyLogoText />
                        </DynamicHeadingElement>
                    </Link>
                </div>
                <ul className={s.navList}>
                    {globalNavLinks.map((link, index) => (
                        <li key={`global-header-${link.href}-${index}`}>
                            <Link
                                href={link.href}
                                aria-label={link.ariaLabel}
                                onClick={handleLinkClick}
                                scroll
                            >
                                {link.text}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
});

GlobalStickyNav.displayName = 'GlobalStickyNav';

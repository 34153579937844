'use client';

import { useEffect, useState } from 'react';
import { useHumbergerMenu } from '@/components/providers/HumbergerMenuProvider/useHumbergerMenu';
import { MenuButton } from '../MenuButton/MenuButton';
import * as s from './style.css';

type Props = {
    /**
     * このスクロール量を超えたらボタンを表示
     *
     * @default 0
     */
    scrollToShowThreshold?: number;
    /**
     * スクロールによる表示機能を有効にするかどうか
     *
     * @default false
     */
    isScrollToShowEnabled?: boolean;
};

/** グローバルに使うハンバーガーメニューのボタン */
export const GlobalHumButton: React.FC<Props> = ({
    scrollToShowThreshold = 0,
    isScrollToShowEnabled = false,
}) => {
    const { open, handleToggle } = useHumbergerMenu();
    const [isVisible, setIsVisible] = useState(isScrollToShowEnabled === false);

    useEffect(() => {
        /** スクロール時のイベントリスナーに追加する処理 */
        const handleScroll = () => {
            if (isScrollToShowEnabled) {
                const currentScrollY = window.scrollY;
                setIsVisible(currentScrollY > scrollToShowThreshold);
            } else {
                setIsVisible(true);
            }
        };

        // コンポーネントがマウントされた直後に現在のスクロール位置をチェック
        handleScroll();

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollToShowThreshold, isScrollToShowEnabled]);

    // isVisibleがfalseかつダイアログが開いていない場合は何も表示しない
    if (!(isVisible || open)) {
        return null;
    }

    return (
        <MenuButton
            className={s.buttonRoot}
            // RadixUIのDialogの仕様で、openがtrueの時はonClickを無効にしないとイベントが二度発火してしまう
            onClick={open ? undefined : handleToggle}
            isCloseButton={open}
        />
    );
};

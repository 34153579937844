import { useContext } from 'react';
import { HumbergerMenuContext } from './HumbergerMenuContext';

/**
 * ハンバーガーメニューの開閉状態を管理するコンテキストの値を存在確認して返すカスタムフック
 */
export const useHumbergerMenu = () => {
    const { open, handleClose, handleOpen, handleToggle } = useContext(HumbergerMenuContext);

    if (open === null || handleClose === null || handleOpen === null || handleToggle === null) {
        throw new Error('useHumbergerMenu must be used within a HumbergerMenuProvider');
    }

    return { open, handleClose, handleOpen, handleToggle };
};

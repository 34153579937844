'use client';

import { useMemo } from 'react';
import { useBreakpoint } from '@/components/providers/BreakpointProvider/useBreakpoint';
import { useHumbergerMenu } from '@/components/providers/HumbergerMenuProvider/useHumbergerMenu';
import { BasicDialog } from '../../Utils/BasicDialog/BasicDialog';
import { NavigationMenu } from '../NavigationMenu/NavigationMenu';
import * as s from './style.css';

/** スマホ・PCによって形を変えるハンバーガーメニュー */
export const HumbergerMenu: React.FC = () => {
    const { isMobile } = useBreakpoint();
    const { open, handleClose } = useHumbergerMenu();
    const menuPosition = useMemo(() => (isMobile ? 'right' : 'top'), [isMobile]);

    return (
        <BasicDialog open={open} onClose={handleClose} transitionFrom={menuPosition} pos="top">
            <div className={s.root}>
                <div className={s.innerContainer}>
                    <NavigationMenu onLinkClick={handleClose} />
                </div>
            </div>
        </BasicDialog>
    );
};

HumbergerMenu.displayName = 'HumbergerMenu';
